import React, { useEffect, useState } from 'react';
import { trio } from 'ldrs';
import styles from './GlobeSpinner.module.css';

trio.register();

const GlobeSpinner = ({ isVisible, onAnimationComplete }) => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    let fadeInTimer, fullVisibilityTimer, fadeOutTimer;
    if (isVisible) {
      // Start fade in
      fadeInTimer = setTimeout(() => setOpacity(0.4), 50);

      // Set up full visibility period
      fullVisibilityTimer = setTimeout(() => {
        // Start fade out
        fadeOutTimer = setTimeout(() => {
          setOpacity(0);
          setTimeout(onAnimationComplete, 650); // Wait for fade out to complete
        }, 650);
      }, 1600); // 1500ms fade-in + 1000ms full visibility
    }

    return () => {
      clearTimeout(fadeInTimer);
      clearTimeout(fullVisibilityTimer);
      clearTimeout(fadeOutTimer);
    };
  }, [isVisible, onAnimationComplete]);

  if (!isVisible && opacity === 0) return null;

  return (
    <div className={styles.spinnerContainer} style={{ opacity }}>
      <l-trio
        size="45"
        speed="1.4"
        color="white"
      ></l-trio>
    </div>
  );
};

export default GlobeSpinner;
