export const INITIAL_COUNTRIES = [
  { name: 'Paraguay', coords: [-58, -23] },
  { name: 'Mongolia', coords: [105, 46] },
  { name: 'Laos', coords: [102, 18] },
  { name: 'Jamaica', coords: [-77.3, 18.1] },
  { name: 'France', coords: [2, 46] },
  { name: 'Germany', coords: [10, 51] },
  { name: 'Iran', coords: [53, 32] },
  { name: 'Mali', coords: [-4, 17] },
  { name: 'Uganda', coords: [32, 1] }
];