import React, { useState, useEffect } from "react";
import "@flaticon/flaticon-uicons/css/all/all.css";
import amsterdamImage from "../../images/amsterdam1.jpeg";
import alienImage from "../../images/alien.jpeg";
import styles from "./suggested-prompt-cards.module.css";

// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

const SuggestedPromptCards = ({ onPromptClick }) => {
  const analytics = getAnalytics();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const prompts = [
    {
      text: ["What", " would an alien invasion be like?"],
      icon: "fi fi-bs-world",
    },
    {
      text: isMobile
        ? ["Where", " should I take psychedelics?"]
        : ["What's", " the best place to take psychedelics?"],
      icon: "fi fi-br-scroll-document-story",
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [typedPrompts, setTypedPrompts] = useState(prompts.map(() => ""));
  const [isTypingComplete, setIsTypingComplete] = useState(
    prompts.map(() => false)
  );

  useEffect(() => {
    prompts.forEach((prompt, index) => {
      let i = 0;
      const typingInterval = setInterval(() => {
        if (i <= prompt.text.join("").length) {
          setTypedPrompts((prev) => {
            const newTypedPrompts = [...prev];
            newTypedPrompts[index] = prompt.text.join("").slice(0, i);
            return newTypedPrompts;
          });
          i++;
        } else {
          clearInterval(typingInterval);
          setIsTypingComplete((prev) => {
            const newIsTypingComplete = [...prev];
            newIsTypingComplete[index] = true;
            return newIsTypingComplete;
          });
        }
      }, 30);

      return () => clearInterval(typingInterval);
    });
  }, []);

  return (
    <div className="flex flex-row justify-between mb-5 gap-2 sm:gap-5 w-full max-w-[700px]">
      {prompts.map((prompt, index) => (
        <button
          key={index}
          className={`relative p-3 sm:p-4 md:p-5 sm:pr-[100px] md:pr-[120px] bg-gradient-to-br from-[rgba(138,93,200,0.5)] to-[rgba(94,53,177,0.5)] text-white rounded-lg cursor-pointer flex flex-col items-start h-[100px] sm:h-[110px] md:h-[130px] text-left border-none transform perspective-1000 rotate-x-0 rotate-y-0 scale-100 transition-all duration-300 ease-in-out shadow-lg backdrop-blur-md w-1/2 hover:shadow-xl hover:translate-y-[-2px] hover:scale-[1.01] outline outline-1 outline-[rgba(176,196,222,0.6)] hover:outline-[rgba(176,196,222,0.8)]`}
          onClick={() => {
            onPromptClick(prompt.text.join(""));
            logEvent(analytics, `button_click_use_prompt`);
          }}
          aria-label={`Use prompt: ${prompt.text.join("")}`}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <div
            className="absolute inset-0 rounded-lg transition-opacity"
            style={{
              background:
                "radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)",
              opacity: hoveredIndex === index ? 1 : 0,
            }}
          />
          <div className="flex flex-col justify-between h-full ">
            <p
              className={`text-[14px] sm:text-[15px] md:text-[16.5px] font-semibold leading-[1.3] sm:leading-[1.4] m-0 pr-0 pl-0 overflow-hidden z-10 text-[#f2f2f2] ${styles.promptText}`}
            >
              <span
                className="inline-block whitespace-pre-wrap overflow-hidden max-w-full"
                style={{
                  animation: isTypingComplete[index]
                    ? "none"
                    : `typing 2s steps(${prompt.text.join("").length}, end)`,
                }}
              >
                <strong>
                  {typedPrompts[index].slice(0, prompt.text[0].length)}
                </strong>
                {typedPrompts[index].slice(prompt.text[0].length)}
              </span>
            </p>
            <div className="flex items-center -ml-[3.3px]">
              <svg
                className={`w-5 h-5 sm:w-6 sm:h-6 transition-transform duration-300 ease-in-out opacity-60 transform rotate-[-90deg] mr-2`}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                style={{
                  transform:
                    hoveredIndex === index
                      ? "translateX(5px) rotate(-90deg)"
                      : "rotate(-90deg)",
                }}
              >
                <path
                  d="M12 5L12 19M12 19L5 12M12 19L19 12"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <img
            src={index === 0 ? alienImage : amsterdamImage}
            alt={index === 0 ? "Alien" : "Amsterdam"}
            className={`absolute right-2 sm:right-3 md:right-5 top-1/2 transform -translate-y-1/2 w-[60px] h-[60px] sm:w-[70px] sm:h-[70px] md:w-[90px] md:h-[90px] object-cover rounded-md shadow-md border-2 border-[rgba(176,196,222,0.4)] ${styles.cardImage}`}
          />
        </button>
      ))}
    </div>
  );
};

export default SuggestedPromptCards;
