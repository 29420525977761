import React, { useState, useEffect } from "react";
import { ImageIcon, SpeakerLoudIcon, PlayIcon } from "@radix-ui/react-icons";
import FeatureCard from "./FeatureCard";
import ScrollIndicator from "./ScrollIndicator";
import { motion, AnimatePresence } from "framer-motion";
import "@flaticon/flaticon-uicons/css/all/all.css";

const MainContent = () => {
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const [featureCardsDisplayed, setFeatureCardsDisplayed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollIndicator(false);
      } else if (featureCardsDisplayed) {
        setShowScrollIndicator(true);
      }
    };

    const handleResize = () => {
      if (window.innerWidth <= 768 && featureCardsDisplayed) {
        setShowScrollIndicator(true);
      } else {
        setShowScrollIndicator(false);
      }
    };

    // Delay to match the feature cards animation
    const timer = setTimeout(() => {
      setFeatureCardsDisplayed(true);
      handleResize();
    }, 1200);

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [featureCardsDisplayed]);

  return (
    <main className="container mx-auto px-4 sm:px-6 lg:px-8 pt-4 sm:pt-6 lg:pt-8 pb-12 sm:pb-16 lg:pb-20">
      <h1 className="mb-6 sm:mb-8 text-center text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-light tracking-tight">
        Narrative-Driven{" "}
        <span className="relative inline-block text-white">
          AI Videos
          <motion.span
            className="absolute left-0 bottom-0 w-full h-2 bg-gradient-to-r from-purple-400 via-yellow-200 to-purple-500 rounded-full opacity-65 sm:translate-y-0 translate-y-[2px]"
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
          ></motion.span>
        </span>
        <br className="hidden sm:block" />
        <motion.span
          className="hidden sm:inline-block bg-gradient-to-r from-purple-600 via-purple-400 to-purple-700 bg-clip-text text-transparent"
          style={{ fontSize: "95%" }}
          initial={{ opacity: 0, y: 7 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.85, delay: 0.8, ease: "easeOut" }}
        >
          Forget Walls of Text
        </motion.span>
      </h1>

      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, delay: 1.5, ease: "easeOut" }}
        className="mx-auto mb-8 sm:mb-12 w-[calc(100%-24px)] sm:w-[calc(80%-24px)] md:w-[calc(75%-24px)] lg:w-[calc(66.666%-24px)] xl:w-[calc(50%-76px)] text-center flex flex-col sm:flex-row justify-center items-center flex-wrap py-2 sm:py-3 px-1 sm:px-2 border border-gray-500 rounded-full shadow-sm"
        style={{
          borderWidth: "0.5px",
          borderColor: "rgba(107, 114, 128, 0.27)",
        }}
      >
        <span className="mb-2 sm:mb-0 mr-1 font-medium bg-gradient-to-r from-[rgb(160,80,234)] via-[rgb(182,125,239)] to-[rgb(160,80,234)] bg-clip-text text-transparent text-[0.9375rem] sm:text-[1.0625rem] md:text-[1.1875rem]">
          <span className="sm:hidden">Forget Walls of Text, </span>
          We use:
        </span>

        <div className="flex flex-row flex-wrap justify-center items-center">
          {[
            { icon: SpeakerLoudIcon, text: "Voice" },
            { icon: ImageIcon, text: "AI-Images" },
            { icon: PlayIcon, text: "Music" },
          ].map((item, index) => (
            <React.Fragment key={item.text}>
              <motion.span
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  duration: 0.8,
                  delay: 1.4 + index * 0.2,
                  ease: "easeOut",
                }}
                className="inline-flex items-center mx-1 my-1 px-[0.5625rem] py-[0.3125rem] sm:px-[0.6875rem] sm:py-[0.40625rem] rounded-full bg-gray-800 bg-opacity-30"
              >
                <item.icon
                  className="w-[0.9375rem] h-[0.9375rem] mr-[0.4375rem] text-purple-400 sm:w-[1.0625rem] sm:h-[1.0625rem]"
                />
                <span className="text-[#dfdfdf] text-[0.9375rem] sm:text-[1.0625rem] font-[400]">
                  {item.text}
                </span>
              </motion.span>
              {index < 2 && (
                <span className="text-gray-400 opacity-50 mx-1 text-[1.0625rem] sm:text-[1.1875rem]">
                  +
                </span>
              )}
            </React.Fragment>
          ))}
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 2.3 }}
        className="grid grid-cols-1 gap-6 sm:gap-8 lg:gap-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:max-w-[110%] 2xl:-mx-[5%] mt-6 sm:mt-8"
      >
        <FeatureCard
          title="Infinite Search"
          description="Explore and search for videos that don't exist."
          image="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/search-WKer4CoABTQ4vqW38xP4lnAtbHNx1z.png"
          isNew={true}
          buttonText="Explore Endlessly"
          imagePadding="p-2"
          buttonColor="bg-blue-800/70 hover:bg-blue-700/70 border border-blue-600"
          iconClass="fi fi-rr-telescope"
        />
        <FeatureCard
          title="Time Machine"
          description="Rewind time & watch videos from around the world."
          image="https://2i2kkpmomojld4ki.public.blob.vercel-storage.com/maybe3-iVFJqArp3TX0WNI2yetCA7SE6sIozx.png"
          isNew={true}
          buttonText="Rewind Time"
          imagePadding="p-2"
          buttonColor="bg-teal-800/70 hover:bg-teal-700/70 border border-teal-600"
          iconClass="fi fi-rr-time-past"
        />
        <FeatureCard
          title="Direct Mode"
          description="Go directly from question to video. Anything goes."
          image="https://o2pepkix6wyhtamc.public.blob.vercel-storage.com/TORSERA/images/SD3Mimage6-vjH800gh5VOvgUDZoIjwEFFDBL5w1R.webp"
          buttonText="Ask Anything"
          imagePadding="p-2"
          buttonColor="bg-purple-800/70 hover:bg-purple-700/70 border border-purple-600"
          iconClass="fi fi-rr-interrogation"
        />
      </motion.div>

      <AnimatePresence>
        {showScrollIndicator && <ScrollIndicator />}
      </AnimatePresence>
    </main>
  );
};

export default MainContent;
