import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import style from "./generative-search-cards.module.css";
import { motion } from "framer-motion";
import SkeletonCard from "./SkeletonCard";
import "@flaticon/flaticon-uicons/css/all/all.css";

const preloadIcons = () => {
  const iconClasses = ["fi-rr-sparkles", "fi-rr-branching"];
  iconClasses.forEach((iconClass) => {
    const tempIcon = document.createElement("i");
    tempIcon.className = iconClass;
    tempIcon.style.position = "absolute";
    tempIcon.style.opacity = "0";
    tempIcon.style.pointerEvents = "none";
    document.body.appendChild(tempIcon);
    setTimeout(() => document.body.removeChild(tempIcon), 0);
  });
};

// Add this new constant for the SVG
const SparkleIcon = () => (
  <svg
    className={style["sparkle-icon"]}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m21.137 11.519-2.726-.779a7.453 7.453 0 0 1 -5.151-5.151l-.779-2.726a.52.52 0 0 0 -.962 0l-.779 2.726a7.453 7.453 0 0 1 -5.151 5.151l-2.726.779a.5.5 0 0 0 0 .962l2.726.779a7.453 7.453 0 0 1 5.151 5.151l.779 2.726a.5.5 0 0 0 .962 0l.779-2.726a7.453 7.453 0 0 1 5.151-5.151l2.726-.779a.5.5 0 0 0 0-.962z"
      fill="white"
    />
  </svg>
);

// Add this new hook after the SparkleIcon component
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return isMobile;
};

// Add after SparkleIcon component
const CaretIcon = () => (
  <svg 
    width="12" 
    height="6" 
    viewBox="0 0 12 6" 
    className={style.caretIcon}
  >
    <path 
      d="M1 5L6 1L11 5" 
      stroke="currentColor" 
      strokeWidth="1.5" 
      strokeLinecap="round" 
      fill="none" 
    />
  </svg>
);

const Card = ({ children, title, description, displayDescription, loading, index, onGenerate, theme = 'default' }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredSection, setHoveredSection] = useState(null);
  const [titleLines, setTitleLines] = useState(1);
  const titleRef = useRef(null);
  const ellipsisRef = useRef(null);
  const isMobile = useIsMobile();
  const hoverTimeoutRef = useRef(null);

  useEffect(() => {
    preloadIcons();
  }, []);

  useLayoutEffect(() => {
    if (titleRef.current && ellipsisRef.current) {
      const titleElement = titleRef.current;
      const ellipsisElement = ellipsisRef.current;
      const range = document.createRange();
      range.selectNodeContents(titleElement);
      const rects = range.getClientRects();
      const lines = rects.length;

      if (lines === 1) {
        // Reduced marginTop from 7px to 4px for single line
        ellipsisElement.style.position = "relative";
        ellipsisElement.style.top = "0";
        ellipsisElement.style.left = "0";
        ellipsisElement.style.marginTop = "1px";
      } else if (lines >= 2) {
        // Multi-line positioning remains the same
        const secondLineRect = rects[1];
        const titleRect = titleElement.getBoundingClientRect();
        const ellipsisLeft = secondLineRect.right - titleRect.left + 7;
        const ellipsisTop =
          secondLineRect.bottom -
          titleRect.top -
          parseInt(window.getComputedStyle(titleElement).lineHeight) +
          7.2;

        ellipsisElement.style.position = "absolute";
        ellipsisElement.style.top = `${ellipsisTop}px`;
        ellipsisElement.style.left = `${ellipsisLeft}px`;
        ellipsisElement.style.marginTop = "0";
      }

      setTitleLines(lines);
      range.detach();
    }
  }, [title]);

  const handleGenerateClick = () => {
    if (onGenerate) {
      onGenerate(title, description);
    }
  };

  const handleSectionHover = (section) => {
    if (section === "bottom") {
      clearTimeout(hoverTimeoutRef.current);
      if (section === null) {
        setHoveredSection(null);
      } else {
        hoverTimeoutRef.current = setTimeout(() => {
          setHoveredSection(section);
        }, 150);
      }
    } else {
      setHoveredSection(section);
    }
  };

  useEffect(() => {
    return () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);

  return (
    <motion.div
      className={`flex flex-col rounded-lg overflow-hidden shadow-lg transition-all duration-300 ease-in-out hover:shadow-2xl h-full border border-blue-400/50 relative ${
        isHovered ? "hover:brightness-90" : ""
      }`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
    >
      {loading ? (
        <SkeletonCard theme={theme} />
      ) : (
        <>
          <div
            className="relative aspect-[16/10] p-0"
            style={{ 
              backgroundColor: theme === 'timeMachine' ? "rgba(54, 117, 221, 0.17)" : "rgba(54, 117, 221, 0.17)",
              background: `linear-gradient(135deg,
                rgba(211, 216, 224, 0.14) 0%,
                rgba(54, 117, 221, 0.27) 35%,
                rgba(92, 107, 177, 0.22) 75%,
                rgba(54, 117, 221, 0.20) 100%
              )`,
              boxShadow: `
                inset 0 0 15px rgba(54, 117, 221, 0.17),
                inset 0 0 25px rgba(211, 216, 224, 0.10)
              `
            }}
          >
            <div className="relative w-full h-full overflow-hidden rounded-t-lg border border-blue-400/50">
              {children}
              <SparkleIcon />
              <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-gray-900/83 to-transparent"></div>
            </div>
          </div>
          <div
            className={style.cardContent}
            data-theme={theme}
          >
            <div className="titleWrapper" style={{ position: "relative" }}>
              <h3
                ref={titleRef}
                className={style.cardTitle}
              >
                {title}
              </h3>
              <div className={style.ellipsisContainer} ref={ellipsisRef}>
                <span className={style.ellipsis}>⋯</span>
              </div>
            </div>
          </div>
          {isMobile && (
            <button
              onClick={handleGenerateClick}
              className="absolute bottom-4 right-4 flex items-center space-x-2 backdrop-blur-sm px-4 py-2 rounded-lg border-2 border-blue-400/60 transition-all duration-300 active:scale-95 hover:border-blue-400/80"
              style={{ 
                transform: 'translateY(-71.5px) translateX(3px)',
                backgroundColor: 'rgba(35, 35, 35, 0.55)',
                boxShadow: '0 0 10px rgba(96, 165, 250, 0.2)'
              }}
            >
              <i className="fi fi-rr-sparkles text-lg translate-y-[3px]" style={{ color: 'rgba(180, 160, 255, 0.9)' }}></i>
              <span className="text-white text-sm font-medium">Generate</span>
            </button>
          )}
          {!isMobile && isHovered && (
            <div
              className="absolute inset-0 flex flex-col hover-hand"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.87)" }}
            >
              <div
                className={`flex-grow flex items-center justify-center cursor-pointer transition-all duration-300 group relative ${style.hoverBackground}`}
                style={{ height: "68%" }}
                onMouseEnter={() => handleSectionHover("top")}
                onMouseLeave={() => handleSectionHover(null)}
                onClick={() => onGenerate(title, description)}
              >
                <div className="flex items-center space-x-2 relative z-10">
                  <i
                    className="fi fi-rr-sparkles text-2xl transition-all duration-300 group-hover:text-white translate-y-[3px]"
                    style={{
                      color: "rgba(180, 160, 255, 0.9)",
                      textShadow:
                        hoveredSection === "top"
                          ? "0 0 8px rgba(255, 255, 255, 0.56)"
                          : "none",
                    }}
                  ></i>
                  <span className="text-gray-100 text-xl font-semibold transition-all duration-300 group-hover:text-white">
                    Generate
                  </span>
                  <svg
                    className={`${style.generateArrow} group-hover:text-white group-hover:translate-x-1`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M14 7l5 5m0 0l-5 5m5-5H3"
                    />
                  </svg>
                </div>
                <div
                  className={`absolute inset-0 transition-all duration-300 ${
                    hoveredSection === "top" ? "opacity-100" : "opacity-0"
                  }`}
                  style={{
                    boxShadow: "inset 0 0 0 1px rgba(59, 130, 246, 0.5), inset 0 0 20px rgba(59, 130, 246, 0.25)",
                    background:
                      "radial-gradient(ellipse 50% 40% at center, rgba(180, 160, 255, 0.055), transparent)",
                    pointerEvents: "none",
                  }}
                ></div>
              </div>
              <div
                className="transition-all duration-300 group relative flex flex-col"
                style={{ 
                  height: hoveredSection === "bottom" ? "50%" : "63px",
                  background: hoveredSection === "bottom" 
                    ? "linear-gradient(180deg, rgba(59, 130, 246, 0.65) 0%, rgba(0, 0, 0, 0.85) 100%)"
                    : "rgba(0, 0, 0, 0.75)",
                  borderTop: "1px solid rgba(59, 130, 246, 0.3)"
                }}
                onMouseEnter={() => handleSectionHover("bottom")}
                onMouseLeave={() => handleSectionHover(null)}
              >
                <div className="flex flex-col items-center justify-center py-2.5">
                  <CaretIcon />
                </div>
                <p 
                  className="px-4 text-gray-100 text-sm font-medium transition-all duration-300 group-hover:text-white"
                  style={{
                    display: '-webkit-box',
                    WebkitLineClamp: hoveredSection === "bottom" ? '3' : '1',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    transition: 'all 0.3s ease-in-out',
                    transform: hoveredSection === "bottom" ? 'translateY(0)' : 'translateY(0)',
                    marginTop: '-2px',
                    opacity: hoveredSection === "bottom" ? '1' : '0.9'
                  }}
                >
                  {displayDescription || description}
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </motion.div>
  );
};

export const GenerativeSearchIdeas = ({
  ideas,
  loadedImages,
  setLoadedImages,
  isLoading,
  onGenerate,
  theme = 'default'
}) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 max-w-[1000px] mx-auto w-full px-4 md:gap-4">
    {isLoading
      ? Array(6).fill(0).map((_, index) => <SkeletonCard key={index} theme={theme} />)
      : ideas.slice(0, 6).map((idea, index) => (
          <Card
            key={index}
            title={idea.title || "Loading..."}
            description={idea.description || "Loading..."}
            displayDescription={idea.displayDescription}
            loading={!idea.ready}
            index={index}
            onGenerate={onGenerate}
            theme={theme}
          >
            {idea.ready && (
              <img
                src={idea.imageUrl}
                alt={`Piece ${index + 1}`}
                className={`w-full h-full object-cover transition-all duration-700 ease-in-out ${
                  loadedImages[index] ? "opacity-100" : "opacity-0"
                }`}
                onLoad={() => {
                  setLoadedImages((prev) => ({ ...prev, [index]: true }));
                }}
              />
            )}
          </Card>
        ))}
  </div>
);
