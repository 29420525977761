import React from "react";
import { ArrowRight } from "lucide-react";

const FeatureCard = ({
  title,
  description,
  image,
  isNew = false,
  buttonText,
  imagePadding = "",
  buttonColor,
  iconClass,
}) => (
  <div className="group bg-zinc-900 rounded-xl overflow-hidden transition-all duration-300 ease-in-out hover:shadow-lg hover:shadow-purple-800/10 w-full mx-auto">
    <div className={`relative aspect-[16/9] overflow-hidden ${imagePadding}`}>
      <div className="w-full h-full overflow-hidden rounded-lg">
        <img
          src={image}
          alt={title}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="absolute inset-0 bg-gradient-to-t from-zinc-900 to-transparent opacity-70 transition-opacity duration-300 group-hover:opacity-60"></div>
    </div>
    <div className="p-3 sm:p-4 pt-2 -mt-2 transition-all duration-300 ease-in-out">
      <div className="flex items-center mb-1">
        <h3 className="text-lg sm:text-xl font-bold text-zinc-100 mr-2 transition-colors duration-300">
          {title}
        </h3>
        {isNew && (
          <span className="bg-purple-800 text-zinc-100 text-xs font-semibold px-2 py-1 rounded-full transition-all duration-300 group-hover:bg-purple-700">
            New
          </span>
        )}
      </div>
      <p className="text-zinc-400 text-xs sm:text-sm mb-2 sm:mb-2.5 transition-colors duration-300 group-hover:text-zinc-300 min-h-[34px] sm:min-h-[38px] overflow-hidden text-ellipsis">
        {description}
      </p>
      <button
        className={`w-full ${buttonColor} text-zinc-100 font-semibold py-1.5 sm:py-2 px-3 sm:px-4 rounded transition-all duration-300 flex items-center justify-center group/button hover:shadow-md hover:shadow-purple-800/20 text-sm sm:text-base`}
      >
        <i className={`${iconClass} mr-1.5 sm:mr-2 text-[0.9em] translate-y-[1.5px]`}></i>
        {buttonText}
        <ArrowRight className="ml-2 sm:ml-3.5 h-3 w-3 sm:h-4 sm:w-4 transition-transform duration-300 group-hover/button:translate-x-1" />
      </button>
    </div>
  </div>
);

export default FeatureCard;
