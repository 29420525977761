import React, { useContext, useEffect, useRef, useState } from "react";
import style from "./pricing-modal.module.css";
import { handleSubscribe } from "../../firebaseutils";
import { AppContext } from "../../context/appContext";

// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

// Components
import { LoadingDots } from "../LoadingDots/LoadingDots";

export const PricingModal = ({
  isPricingModalOpen,
  pricingOptions,
  setIsPricingModalOpen,
}) => {
  const { setAlertOptions, userData } = useContext(AppContext);

  const analytics = getAnalytics();

  const [loadingTier, setLoadingTier] = useState(null);

  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsPricingModalOpen(false);
      }
    };

    if (isPricingModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPricingModalOpen, setIsPricingModalOpen]);

  function handleClick(priceId, optionName) {
    const backendTierName = optionName;
    handleSubscribe(userData, priceId, backendTierName); // Pass the correct tier name
    setLoadingTier(optionName);
    logEvent(analytics, `button_click_purchase`, { value: backendTierName }); // Log the correct tier name
  }

  return (
    <div
      className={style["pricing-modal"]}
      id="pricingModal"
      tabIndex="-1"
      aria-labelledby="pricingModalLabel"
      aria-hidden="true"
      style={{ display: isPricingModalOpen ? "inherit" : "none" }}
    >
      <div className={style["modal-content"]} ref={modalRef}>
        <div className={style["modal-header"]}>
          <h1 className={style["modal-title"]} id="loginModalLabel">
            Choose Your Plan
          </h1>
          <button
            type="button"
            className={style["btn-close"]}
            aria-label="Close"
            onClick={() => setIsPricingModalOpen(false)}
          >
            X
          </button>
        </div>
        <div className={style["modal-body"]}>
          <div className={style["pricing-container"]}>
            <div className={style["priced-options"]}>
              {["starter", "pro", "premium"].map((tier) => (
                <div
                  key={tier}
                  className={`${style["card"]} ${
                    tier === "starter"
                      ? style["starter"]
                      : tier === "pro"
                        ? style["pro"]
                        : tier === "premium"
                          ? style["premium"]
                          : ""
                  }`}
                >
                  <div className={style["card-header"]}>
                    <h2>
                      {tier === "starter"
                        ? "Starter"
                        : tier === "pro"
                          ? "Pro"
                          : "Premium"}
                    </h2>
                  </div>
                  <div className={style["card-body"]}>
                    <p className={style["price"]}>
                      ${tier === "starter" ? "4" : tier === "pro" ? "8" : "12"}{" "}
                      <span className={style["freq"]}>/ month</span>
                    </p>
                    <p className={style["discount"]}>
                      {tier === "starter"
                        ? "For anyone looking to dip their toes in and explore a bit more."
                        : tier === "pro"
                          ? "A great balance for exploring, watching, and creating."
                          : "For those that want to create and enjoy lots of content regularly."}
                    </p>
                    <div className={style["divider"]}></div>
                    <ul className={style["features"]}>
                      <li className={style["credits"]}>
                        <span>
                          {tier === "starter"
                            ? "500 credits"
                            : tier === "pro"
                              ? "1200 credits"
                              : "2000 credits"}
                        </span>{" "}
                        (<span className={style["up-to"]}>up to </span>
                        <span className={style["hours"]}>
                          {tier === "starter"
                            ? "~50 minutes"
                            : tier === "pro"
                              ? "~2 hours"
                              : "~3.3 hours"}
                        </span>
                        ) refilled monthly
                      </li>
                      {tier !== "starter" && (
                        <li className={style["more-features"]}>
                          More features coming soon
                        </li>
                      )}
                    </ul>
                    <div className={style["spacer"]}></div>
                  </div>
                  <div className={style["card-footer"]}>
                    <a
                      className={`${style["subscribe-btn"]} ${
                        userData?.subscription_tier === tier
                          ? style["active-subscription"]
                          : ""
                      }`}
                      onClick={() => {
                        const option = pricingOptions.find(
                          (option) => option.name === tier
                        );
                        if (option) {
                          handleClick(option.default_price, option.name);
                        } else {
                          console.error(
                            `No pricing option found for tier: ${tier}`
                          );
                        }
                      }}
                      disabled={userData?.subscription_tier === tier} // Updated comparison
                    >
                      {userData?.subscription_tier === tier ? ( // Updated condition
                        "Active"
                      ) : loadingTier === tier ? (
                        <LoadingDots />
                      ) : (
                        "Subscribe"
                      )}
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className={style["free-option"]}>
              <div className={style["card"]} id={style["basic-card"]}>
                <h2>Free</h2>
                <div className={style["price-container"]}>
                  <p className={style["price"]}>$0</p>
                  <span className={style["freq"]}>/ month</span>
                </div>
                <p className={style["discount"]}>
                  Try out our features for a bit at no cost.
                </p>
                <div className={style["divider"]}></div>
                <ul className={style["features"]}>
                  <li className={style["credits"]}>
                    <span>175 credits</span> (
                    <span className={style["up-to"]}>up to </span>
                    <span className={style["hours"]}>~17.5 minutes</span>)
                    refilled monthly
                  </li>
                </ul>
                {/* Removed the subscribe button */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
