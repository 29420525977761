import { createContext, useState } from "react";
import "firebase/compat/firestore";
import axios from "axios";

import { checkRateLimit } from "../utils/rateLimit";

// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

export const VideoContext = createContext({});

export const VideoProvider = (props) => {
  const { children } = props;

  const analytics = getAnalytics();

  const [ideaInputText, setIdeaInputText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isVideo, setIsVideo] = useState(false);
  const [nextVideoSuggestions, setNextVideoSuggestions] = useState([]);
  const [videoOptions, setVideoOptions] = useState({
    currentIndex: null,
    finalPromptResponseHistory: [],
    isPlaying: false,
    intervalId: null,
    startTime: null,
    elapsedTime: null,
    segmentDurations: [],
    imgPaths: [],
    musicFileUrl: null,
    subtitles: [],
    subtitleIntervalId: null,
    voiceoverUrl: null,
    pausedTime: 0,
  });

  function handleSubmit(
    requestType,
    storyPrompt,
    storyOptions,
    setAlertOptions,
    setIdeaInputText,
    setUserData
  ) {
    try {
      checkRateLimit();
    } catch (error) {
      setAlertOptions({
        isOpen: true,
        text: "You have reached the rate limit. Please wait before generating again.",
        status: "warning",
      });
      setTimeout(
        () => setAlertOptions((prev) => ({ ...prev, isOpen: false })),
        4000
      );
      return;
    }

    setVideoOptions((prev) => ({
      ...prev,
      currentIndex: null,
      finalPromptResponseHistory: [],
      isPlaying: false,
      intervalId: null,
      startTime: null,
      elapsedTime: null,
      segmentDurations: [],
      imgPaths: [],
      musicFileUrl: null,
      subtitles: [],
      subtitleIntervalId: null,
      voiceoverUrl: null,
      pausedTime: 0,
    }));

    setIsLoading(true);
    setIsVideo(true);
    logEvent(analytics, `button_click_generate_story`);
    localStorage.removeItem("pausedTime");

    axios
      .post(`${process.env.REACT_APP_API_URL}/check_nsfw`, {
        prompt: storyPrompt,
      })
      .then((response) => {
        if (response.data.isNSFW) {
          setIsLoading(false);
          setIsVideo(false);
          setAlertOptions({
            isOpen: true,
            text: "Error - nudity detected.",
            status: "warning",
          });
          setTimeout(
            () => setAlertOptions((prev) => ({ ...prev, isOpen: false })),
            4000
          );
          return;
        }

        const requestData = {
          requestType: requestType,
          storyPrompt: storyPrompt,
          storyStyle: storyOptions.mode,
          addonReplaceMode: storyOptions.style,
          length: storyOptions.length,
          voice: storyOptions.voice,
          music: "Auto",
          finalPromptResponseHistory: videoOptions.finalPromptResponseHistory,
        };

        console.log("requestData", requestData);

        logEvent(analytics, `generate_story_options_style`, {
          value: storyOptions.mode,
        });
        logEvent(analytics, `generate_story_options_length`, {
          value: storyOptions.length,
        });
        logEvent(analytics, `generate_story_options_voice`, {
          value: storyOptions.voice,
        });

        axios
          .post(`${process.env.REACT_APP_API_URL}/process_story`, requestData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            withCredentials: true,
          })
          .then((response) => {
            logEvent(analytics, `generate_story_success`);
            const data = response.data;
            setUserData((prev) => ({
              ...prev,
              credits: data.remainingCredits,
            }));
            // const currentStoryId = data.story_id;
            getNextVideoSuggestions(storyPrompt);
            setIdeaInputText("");
            // Fade out the loader when images are ready

            // After the transition, hide the loader and display other elements
            setTimeout(() => {
              setIsLoading(false);

              // Sort the image paths based on their prompt index
              const sortedImagePaths = data.imagePaths.sort((a, b) => {
                const aIndex = extractPromptIndex(a);
                const bIndex = extractPromptIndex(b);
                return aIndex - bIndex;
              });

              setVideoOptions({
                ...videoOptions,
                finalPromptResponseHistory: data.finalPromptResponseHistory,
                imgPaths: [...sortedImagePaths],
                musicFileUrl: data.musicFileUrl,
                voiceoverUrl: data.voiceoverUrl,
              });

              // Helper function to extract the prompt index from the image URL
              function extractPromptIndex(url) {
                const match = url.match(/ai_art_prompt_(\d+)_\d+\.png/);
                return match ? parseInt(match[1], 10) : 0;
              }

              // Preload the first image
              if (sortedImagePaths.length > 0) {
                preloadImage(sortedImagePaths[0]);
              }

              // Store the adjusted segment durations
              setVideoOptions((prev) => ({
                ...prev,
                segmentDurations: data?.adjustedSegmentDurations,
              }));

              // Apply animation durations to images
              applyAnimationDurations(
                document.querySelectorAll(".panning-image"),
                data?.adjustedSegmentDurations
              );

              // Fetch and process SRT file
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}/get_srt_content`,
                  { srt_url: data.srtUrl },
                  {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    withCredentials: true,
                  }
                )
                .then((response) => {
                  const subtitles = parseSRT(response.data.srt_content);
                  setVideoOptions((prev) => ({
                    ...prev,
                    subtitles: subtitles,
                  }));
                })
                .catch((error) => {
                  console.error(
                    "Error fetching or processing SRT file:",
                    error
                  );
                });

              // Reset slideshow state
            }, 850); // 850ms matches the duration of the fade-out transition
          })
          .catch((error) => {
            console.error("Error:", error);
            setIsLoading(false);
            setIsVideo(false);

            if (error.response) {
              logEvent(analytics, `generate_story_error`);
              if (error.response.status === 401) {
                logEvent(analytics, `generate_story_error_unauthorized`);
                setAlertOptions({
                  isOpen: true,
                  text: "You are not authenticated. This may be a result of your cookie settings.",
                  status: "warning",
                });
              } else if (error.response.status === 403) {
                logEvent(
                  analytics,
                  `generate_story_error_insufficient_credits`
                );
                setAlertOptions({
                  isOpen: true,
                  text: "You do not have enough credits to generate a story. Please upgrade your plan to get more credits.",
                  status: "warning",
                });
              } else {
                logEvent(analytics, `generate_story_error_other`);
                setAlertOptions({
                  isOpen: true,
                  text: "There was an error processing your request. Please try again.",
                  status: "warning",
                });
              }
              setTimeout(
                () => setAlertOptions((prev) => ({ ...prev, isOpen: false })),
                4000
              );
            } else {
              setAlertOptions({
                isOpen: true,
                text: "There was an error processing your request. Please try again.",
                status: "warning",
              });
              setTimeout(
                () => setAlertOptions((prev) => ({ ...prev, isOpen: false })),
                4000
              );
            }
          });
      })
      .catch((error) => {
        setIsLoading(false);
        setIsVideo(false);
        setAlertOptions({
          isOpen: true,
          text: "There was an error processing your request. Please try again.",
          status: "warning",
        });
        setTimeout(
          () => setAlertOptions((prev) => ({ ...prev, isOpen: false })),
          4000
        );
        console.error("Error during NSFW check:", error);
      });
  }

  function getNextVideoSuggestions(storyPrompt) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/get_suggestions`, {
        params: {
          storyPrompt: storyPrompt,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        setNextVideoSuggestions(response.data.suggestions);
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching suggestions:", error);
      });
  }

  function applyAnimationDurations(images, segmentDurations) {
    images.forEach((img, index) => {
      if (index < segmentDurations.length) {
        img.style.animationDuration = `${segmentDurations[index]}s`;
      }
    });
  }

  function preloadImage(url) {
    var img = new Image();
    img.src = url;
  }

  function parseSRT(srt) {
    const subtitles = [];
    const srtLines = srt.split("\n\n");
    for (const line of srtLines) {
      const parts = line.split("\n");
      if (parts.length >= 3) {
        const times = parts[1].split(" --> ");
        subtitles.push({
          start: timeToSeconds(times[0]),
          end: timeToSeconds(times[1]),
          text: parts.slice(2).join(" "),
        });
      }
    }
    return subtitles;
  }

  function timeToSeconds(time) {
    const [hours, minutes, seconds] = time.split(":");
    const [secs, millis] = seconds.split(",");
    return (
      Number(hours) * 3600 +
      Number(minutes) * 60 +
      Number(secs) +
      Number(millis) / 1000
    );
  }

  const videoContext = {
    handleSubmit,
    ideaInputText,
    isLoading,
    isVideo,
    nextVideoSuggestions,
    setIdeaInputText,
    setIsVideo,
    setVideoOptions,
    videoOptions,
  };

  return (
    <VideoContext.Provider value={videoContext}>
      {children}
    </VideoContext.Provider>
  );
};
